<template>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
        <v-card tile>
            <v-toolbar color="primary" dark height="70px">
                <v-row align="center">
                    <v-col cols="2">
                        <v-img :src="imgIcon" contain max-width="40"/>
                    </v-col>
                    <v-col cols="8" class="text-truncate text-center">
                        {{ editMode ? 'Editar' : 'Crear' }} Convenio
                    </v-col>
                    <v-col cols="2" align="right">
                        <v-btn icon @click="hide">
                            <v-icon v-text="'mdi-close'"/>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-card-text>
                <v-form v-model="valid">
                    <v-row class="mt-3">
                        <v-col cols="12">
                            <kimsa-text-field
                                label="Nombre"
                                :rules="allRules.name"
                                @change="name = $event"
                                :value="name"
                                outlined dense
                                force-value
                            />
                        </v-col>
                        <v-col cols="12">
                            <kimsa-text-field
                                label="Teléfono"
                                :rules="allRules.phone"
                                @change="phone = $event"
                                :value="phone"
                                outlined dense
                                force-value
                                :prefix="phoneCode"
                            />
                        </v-col>
                        <v-col cols="12">
                            <kimsa-select
                                label="Tipo"
                                :rules="allRules.type"
                                @change="type = $event"
                                :value="type"
                                :items="typeOptions"
                                outlined dense no-object
                                force-value
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row dense class="text-center">
                    <v-col cols="6" v-if="laboratoryId">
                        <v-btn color="error" x-large depressed class="normal-btn" @click="deleteLaboratory"
                               :loading="loadingDelete" :disabled="loading">
                            Eliminar
                        </v-btn>
                    </v-col>
                    <v-col :cols="laboratoryId ? 6 : 12">
                        <v-btn color="primary" x-large depressed class="normal-btn" @click="save" :loading="loading"
                               :disabled="disabledSave">
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import {rules} from "@/utils/Rules";
import httpService from "@/services/httpService";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";

export default {
    name: "LaboratoryDialog",
    components: {KimsaSelect, KimsaTextField},
    data() {
        return {
            dialog: false,
            editMode: false,
            loading: false,
            laboratoryId: null,
            name: '',
            phone: '',
            type: '',
            typeOptions: ['Laboratorio', 'Centro endoscópico', 'Cardiológico', 'Ecografía'],
            loadingDelete: false,
            valid: false,
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        allRules() {
            return {
                name: [rules.required, rules.minLength(this.name, 3)],
                phone: [],
                type: [],
            }
        },
        disabledSave(vm = this) {
            return vm.laboratoryId ? !vm.valid || vm.loadingDelete : !vm.valid
        }
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            vm.reset()
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.laboratoryId = null
            vm.name = ''
            vm.phone = ''
            vm.type = ''
        },
        setLaboratory(laboratory) {
            const vm = this
            vm.editMode = true
            vm.laboratoryId = laboratory.id
            vm.name = laboratory.name
            vm.phone = laboratory.phone
            vm.type = laboratory.type
        },
        async save() {
            const vm = this
            vm.loading = true
            let payload = {name: vm.name, phone: vm.phone, type: vm.type}
            let url = vm.editMode ? `laboratories/${vm.laboratoryId}/update` : 'laboratories/create'
            let response = await httpService.post(url, payload)
            vm.loading = false
            vm.hide()
            vm.toast(response.data.message)
            vm.$emit('change')
        },
        async deleteLaboratory() {
            const vm = this
            vm.loadingDelete = true
            let response = await httpService.delete(`laboratories/${vm.laboratoryId}/delete`)
            vm.loadingDelete = false
            vm.hide()
            vm.toast(response.data.message)
            vm.$emit('change')
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

</style>