<template>
    <v-list>
        <v-list-item v-for="(laboratory,i) in laboratories" :key="i" link @click="$emit('click', laboratory)">
            <v-list-item-content>
                <v-list-item-title class="laboratory-name" v-text="laboratory.name"/>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: "Laboratories",
    props: {
        laboratories: {
            type: Array,
            default: () => [],
        },
    },
}
</script>

<style scoped>

.laboratory-name {
    text-align: center;
    background-color: var(--v-ternary-base);
    padding: 8px;
    border-radius: 12px;
    color: #4d4d4d;
}

</style>