<template>
    <master-panel>
        <template v-slot:body>
            <v-row>
                <v-col cols="12" class="text-center py-0">
                    <small>
                        Estimado doctor, si conoce algún centro de
                        salud donde su paciente puede acceder de
                        manera más rápida a los exámenes, agradeceremos
                        que pueda indicarlo a continuación:
                    </small>
                </v-col>
                <v-col cols="12" align="right">
                    <v-btn color="primary" small depressed class="normal-btn" @click="showLaboratory">
                        Crear convenio
                    </v-btn>
                </v-col>
                <v-col cols="12" v-if="loading">
                    <loading-spinner line height="0"/>
                </v-col>
                <v-col cols="12" v-else>
                    <laboratories @click="showEditLaboratory" :laboratories="laboratories"/>
                </v-col>
                <v-col cols="12" class="text-center" v-if="laboratories.length === 0">
                    <small>{{ loading ? 'Cargando...' : 'Sin resultados' }}</small>
                </v-col>
            </v-row>
            <laboratory-dialog @change="loadLaboratories()" ref="laboratory"/>
        </template>
        <template v-slot:buttons>
            <v-row dense>
                <v-col cols="6">
                    <v-btn color="primary" small depressed class="normal-btn" @click="goBack">
                        Volver
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn color="primary" small depressed class="normal-btn" @click="goHome">
                        Dashboard
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </master-panel>
</template>

<script>
import MasterPanel from "@/components/layouts/MasterPanel";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import Laboratories from "@/components/lists/Laboratories";
import httpService from "@/services/httpService";
import LaboratoryDialog from "@/components/doctor/LaboratoryDialog";

export default {
    components: {LaboratoryDialog, Laboratories, LoadingSpinner, MasterPanel},
    data() {
        return {
            loading: false,
            laboratories: []
        }
    },
    async mounted() {
        const vm = this
        await vm.loadLaboratories()
    },
    methods: {
        async loadLaboratories() {
            const vm = this
            vm.loading = true

            let response = await httpService.get(`doctor/${vm.currentUser.id}/laboratories`)
            // console.log('loadLaboratories', response)
            vm.laboratories = response.data.laboratories

            vm.loading = false
        },
        showLaboratory() {
            const vm = this
            vm.$refs.laboratory.show()
        },
        showEditLaboratory(laboratory) {
            const vm = this
            vm.$refs.laboratory.setLaboratory(laboratory)
            vm.$refs.laboratory.show()
        },
    }
}
</script>

<style scoped>

</style>